@import '../abstracts/variables';
@import '../abstracts/helpers';

.suppliers-carousel-wrapper {
  background: @neutral-gray;
  margin: @spacer * 10 0;
  padding: @spacer * 5;
  border-radius: 50px; // Magic number to match design

  .description-text {
    margin-top: @spacer;
    color: @B800;
    font-size: @font-size-large;
    max-width: 744px;
    margin-bottom: @spacer * 3;
  }

  h1 {
    color: @navy;
  }

  .sub-heading {
    color: @dim-darker;
    margin-top: @spacer;
    margin-bottom: 0;
  }

  .browse-brands {
    font-size: @font-size-large;
    font-weight: 700;

    a {
      color: @primary-dark;
    }

    @media @below-md {
      margin-top: @spacer;
      padding-left: 0;
    }
  }

  .supplier-carousel {
    overflow: visible;
    margin: 0 -@spacer * 4;

    :global {
      .scroll-container-ref {
        padding: @spacer 0;

        > div {
          &:first-child {
            margin-left: @spacer * 5;
          }

          &:last-child {
            margin-right: @spacer * 5;
          }

          // Set carousel boundary match container boundary
          @media @below-md {
            margin-left: @spacer * 2;
          }

          @media @below-sm {
            &:first-child {
              margin-left: @spacer * 4;
            }

            &:last-child {
              margin-right: -(@spacer * 2);
            }
          }
        }
      }
    }
  }
}
