@import 'abstracts/variables';
@import 'Carousel.module';

.nav-right-padding {
  padding-right: @spacer * 2.5;
}

.catalog-heading-area {
  row-gap: @spacer;

  :global(.rs-btn-subtle) {
    color: @B600;
    font-weight: 600;

    &:hover,
    &:focus {
      background: none;
      text-decoration: underline;
    }
  }

  .catalog-header-subheader {
    color: @B800;
    font-size: @font-size-base + 2; // Match design
  }
}

.products-count {
  color: @B600;
}

.back-to-top {
  @bottom-position: 105px; // Match the figma design

  position: fixed;
  right: 24px; // Match the figma design
  bottom: @bottom-position;
  border-radius: @border-radius-base - 2;
  font-weight: 500;
  padding: @spacer @spacer * 2;
  color: @headings-color;
  z-index: @gradientFadeZIndex + 2; // Should be above Carousel arrow button
  background: #fff;
  font-family: @headings-font-family;
  transition: bottom 0.3s ease-out;
  box-shadow:
    0 0 15px 10px rgba(202, 202, 202, 0.25),
    0 0 10px rgba(0, 0, 0, 0.06),
    0 4px 4px rgba(194, 194, 194, 0.12);

  &:hover {
    bottom: @bottom-position + 3;
    color: @headings-color;
    background: #fff;
  }
}
