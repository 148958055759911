@import '../abstracts/variables';
@import '../abstracts/helpers';

.landing-carousel-wrapper {
  overflow: visible;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: @spacer * 2;
    margin: 0 @spacer * 4;
    color: @white;

    a {
      color: @primary;
    }

    @media @below-md {
      flex-direction: column;
    }
  }

  .carousel-base-styles {
    margin: 0 -@spacer 0 @spacer;

    & > div {
      max-width: 100%;

      @media @below-sm {
        margin: 0;
      }
    }

    :global {
      .scroll-container-ref {
        margin: 0;
        margin-right: 0;
        padding: @spacer 0;

        > div {
          margin-left: @spacer * 3;

          &:first-child {
            margin-left: @spacer * 4;
          }

          &:last-child {
            margin-right: @spacer * 4;
          }

          // Set carousel boundary match container boundary
          @media @below-md {
            margin-left: @spacer * 2;
          }

          @media @below-sm {
            &:first-child {
              margin-left: @spacer * 2;
            }

            &:last-child {
              margin-right: @spacer * 2;
            }
          }
        }
      }

      .gradient-element-ref {
        // Give a custom gradient height/width according to category card height
        height: 270px !important;
        width: 200px;

        @media @below-md {
          height: 220px !important; // magin number for smaller size gradient
        }
      }
    }

    // Set carousel boundary match container boundary
    @media @below-md {
      margin: 0 -(@spacer * 5) 0 -(@spacer * 3);
    }

    @media @below-sm {
      margin: 0;
    }
  }
}
