@import '../abstracts/variables';
@import '../abstracts/helpers';
@import '../ProductCard.module';

.category-card {
  .product-card;
  @card-dimension: 250px;

  height: @card-dimension;
  min-width: @card-dimension;
  border-radius: @border-radius-base;
  background: @white;

  .body {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
  }

  .category-name {
    flex-shrink: 1;
    flex-grow: 0;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: @spacer * 2;
    color: @headings-color;
    font-size: @font-size-base + 2;
    font-weight: 700;
    font-family: @headings-font-family;
  }

  .image-container {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
  }

  :global {
    .rs-panel {
      padding: @spacer * 2.5;
      box-shadow: 0 0 10px rgb(0 0 0 / 6%), 0 4px 4px rgb(194 194 194 / 12%);
    }
  }

  @media @below-md {
    @card-dimension: 190px;

    height: @card-dimension;
    min-width: @card-dimension;
  }
}
